/**
 * Syntax highlighting styles
 */
 .highlighter-rouge div {
  color: #dce6e6;
  background: #002b36;
  margin-bottom: 1rem;
}

.highlight {
  color: #dce6e6;
  background: #002b36;

    & pre { margin: 0; padding: 1rem; }

    .c     { color: #657b83; font-style: italic } // Comment
    .err   { color: #002b36; background-color: #dc322f } // Error
    .k     { color: #6c71c4; font-weight: bold } // Keyword
    .o     { color: #93a1a1; font-weight: bold } // Operator
    .cd    { color: #657b83; } 
    .cm    { color: #657b83; font-style: italic } // Comment.Multiline
    .cp    { color: #b58900; font-weight: bold } // Comment.Preproc
    .c1    { color: #657b83; font-style: italic } // Comment.Single
    .cs    { color: #657b83; font-weight: bold; font-style: italic } // Comment.Special
    .gd    { color: #000; background-color: #fdd } // Generic.Deleted
    .gd .x { color: #dc322f; } // Generic.Deleted.Specific
    .ge    { font-style: italic } // Generic.Emph
    .gr    { color: #a00 } // Generic.Error
    .gh    { color: #268bd2; background-color: #002b36; font-weight: bold; } // Generic.Heading
    .gi    { color: #000; background-color: #dfd } // Generic.Inserted
    .gi .x { color: #859900; } // Generic.Inserted.Specific
    .go    { color: #888 } // Generic.Output
    .gp    { color: #555 } // Generic.Prompt
    .gs    { font-weight: bold } // Generic.Strong
    .gu    { color: #aaa } // Generic.Subheading
    .gt    { color: #a00 } // Generic.Traceback
    .kc    { color: #cb4b16; font-weight: bold } // Keyword.Constant
    .kd    { color: #cb4b16; font-weight: bold } // Keyword.Declaration
    .kn    { color: #6c71c4; font-weight: bold }
    .kp    { color: #6c71c4; font-weight: bold } // Keyword.Pseudo
    .kr    { color: #6c71c4; font-weight: bold } // Keyword.Reserved
    .kt    { color: #cb4b16; font-weight: bold } // Keyword.Type
    .kv    { color: #6c71c4; font-weight: bold }
    .m     { color: #859900 } // Literal.Number
    .s     { color: #859900 } // Literal.String
    .na    { color: #268bd2 } // Name.Attribute
    .nb    { color: #0086B3 } // Name.Builtin
    .nc    { color: #b58900; font-weight: bold } // Name.Class
    .no    { color: #b58900 } // Name.Constant
    .ni    { color: #800080 } // Name.Entity
    .ne    { color: #900; font-weight: bold } // Name.Exception
    .nf    { color: #900; font-weight: bold } // Name.Function
    .nn    { color: #b58900 } // Name.Namespace
    .nt    { color: #b58900 } // Name.Tag
    .nv    { color: #008080 } // Name.Variable
    .ow    { color: #93a1a1; font-weight: bold } // Operator.Word
    .p     { color: #93a1a1 } 
    .pi    { color: #93a1a1 } 
    .w     { color: #93a1a1; background-color: #002b36; } // Text.Whitespace
    .mb    { color: #859900 } 
    .mf    { color: #859900 } // Literal.Number.Float
    .mh    { color: #859900 } // Literal.Number.Hex
    .mi    { color: #859900 } // Literal.Number.Integer
    .mo    { color: #859900 } // Literal.Number.Oct
    .mx    { color: #859900 } 
    .sb    { color: #859900 } // Literal.String.Backtick
    .sc    { color: #859900 } // Literal.String.Char
    .sd    { color: #859900 } // Literal.String.Doc
    .s2    { color: #859900 } // Literal.String.Double
    .se    { color: #d33682 } // Literal.String.Escape
    .sh    { color: #859900 } // Literal.String.Heredoc
    .si    { color: #2aa198 } // Literal.String.Interpol
    .sx    { color: #859900 } // Literal.String.Other
    .sr    { color: #2aa198 } // Literal.String.Regex
    .s1    { color: #859900 } // Literal.String.Single
    .ss    { color: #859900 } // Literal.String.Symbol
    .bp    { color: #999 } // Name.Builtin.Pseudo
    .vc    { color: #008080 } // Name.Variable.Class
    .vg    { color: #008080 } // Name.Variable.Global
    .vi    { color: #008080 } // Name.Variable.Instance
    .il    { color: #859900 } // Literal.Number.Integer.Long
  }